
/* Fonts */
:root {
  --font-default: 'Poppins', sans-serif;
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Poppins', sans-serif;



}

/* Colors */
:root {
  --color-default: #212529;
  --color-primary: #ce1212;
  --color-secondary: #37373f;
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: var(--font-default);
  color: var(--color-default);
  background: #FFC794 !important;
  overflow-x:hidden !important;
}

a {
  color: var(--color-primary);
  text-decoration: none !important;
}

a:hover {
  color: #ec2727;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  margin: 0;
  padding: 0;
  margin-left:20px !important;
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  
  padding: 80px 0;

}

.section-bg {
  background-color: #eee;

}

.section-header {
  text-align: center;
  padding-bottom: 30px;
}

.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #7f7f90;
  /* text-transform: uppercase; */
  font-family: var(--font-default);
}

.section-header p {
  margin: 0;
  font-size: 48px;
  font-weight: 400;
  font-family: var(--font-primary);
}

.section-header p span {
  color: var(--color-primary);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  color: #c12f2e;
  text-align: left;
  background-color: #ffc794;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.accordion-button::after {
  flex-shrink: 0;
  margin-left: 10px;
  margin-top: 5px;
  content: url('./img/arrow-down.svg');
  width: 22px; /* Set the width as needed */
  height: 22px; /* Set the height as needed */
  transition: var(--bs-accordion-btn-icon-transition);
}



.accordion-item {
  color: var(--bs-accordion-color);
  background: #ffc794 !important;
  border: transparent !important;
  background-color: var(--bs-accordion-bg) !important;
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;
}

.accordion-button:not(.collapsed) {
  color: #c12f2e;
  background-color: #ffc794 !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color:#ffc794 !important;
  outline: 0;
  box-shadow: none;
}

.form-check-input{
  background-color: #FEDEC0;
  border-color:#FEDEC0;
}

.form-check-input:checked {
  background-color: #c12f2e;
  border-color: #c12f2e;
}

.form-check-input:focus {
  border-color: #c12f2e;
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #FEDEC0;
  outline: 0;
  box-shadow: none;
}

.form1:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #FEDEC0;
  outline: 0;
  box-shadow: none;
}

.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
  background: #ffdebf;
  border: 2px solid #cc3332;
  border-radius: 16px;
}

.form-floating>label {
  position: absolute;
  top: -25%;
  left: 30px;
  z-index: 2;
  height: 50%;
  padding: .2rem .75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity .1s ease-in-out,transform .1s ease-in-out;
  background: #ffdebf;
}

.form-floating>.form-control-plaintext~label::after, .form-floating>.form-control:focus~label::after, .form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  /* height: 1.5em; */
  content: "";
  background-color: #ffdebf;
  /* border-radius: var(--bs-border-radius); */
}

.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  color: #101A24;

  transform: scale(.85) translateY(-.2rem) translateX(.15rem);
}

#floatingInput::placeholder {
  color: #A3A3A3;
  font-size:14px;
  font-weight:400;
}
#floatingInput1 {
width: 290px;
}

.modal-header {
 border-bottom:none;
}

.border-btn{
border: 1px solid var(--Primary-Red, #CC3332);
color: var(--Primary-Red, #CC3332);
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 30px;
padding: 12px 32px;
border-radius: 14px !important;


}

.border-btn:hover{
  border-radius: 8px;
border: 1px solid var(--Primary-Red, #CC3332);
color: var(--Primary-Red, #CC3332);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px;

}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

.btn-link{
  text-decoration: none !important;
}
/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: rgba(55, 55, 63, 0.05);
  margin-top: 90px;
}

@media (max-width: 575px) {
  .breadcrumbs {
    margin-top: 70px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #676775;
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs h2 {
    margin-bottom: 10px;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: #ec2727;
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  animation-delay: -0.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  /* background: #fff; */
  transition: all 0.5s;
  z-index: 997;
  height: 90px;
  /* border-bottom: 1px solid #fff; */
}

@media (max-width: 575px) {
  .header {
    height: 70px;
  }
}

.header.sticked {
  border-color: #fff;
  border-color: #eee;
  background: #C12F2E;
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 28px;
  font-weight: 700;
  color: #000;
  margin: 0;
  font-family: var(--font-secondary);
}

.header .logo h1 span {
  color: var(--color-primary);
}

.header .btn-book-a-table,
.header .btn-book-a-table:focus {
  font-size: 14px;
  color: #fff;
  background: var(--color-primary);
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  transition: 0.3s;
}

.header .btn-book-a-table:hover,
.header .btn-book-a-table:focus:hover {
  color: #fff;
  background: rgba(206, 18, 18, 0.8);
}

section {
  scroll-margin-top: 90px;
}

.header-btn{
  color:#FEDEC0;
  font-size: 24px;
  font-weight: 600;
}

.header-btn:hover{
  color:#FEDEC0;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar>ul>li {
    white-space: nowrap;
    padding: 10px 0 10px 28px;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 500;
    color: #FEDEC0;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar>ul>li>a:before {
    /* content: ""; */
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: var(--color-primary);
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar a:hover:before,
  .navbar li:hover>a:before,
  .navbar .active:before {
    visibility: visible;
    width: 100%;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #FEDEC0;
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 28px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 400px;
    border-left: 1px solid #666;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 0 10px 0;
    margin: 0;
    background: #C12F2E;
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-family: var(--font-secondary);
    /* border-bottom: 2px solid rgba(255, 255, 255, 0.8); */
    font-size: 16px;
    font-weight: 600;
    color: #ffc794;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar li:hover>a {
    color: #000;
  }

  .navbar .active,
  .navbar .active:focus {
    color: #ffc794;
    border-color: var(--color-primary);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid #eee;
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    color: #ffc794;
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    z-index: 9999;
    margin: 0 10px 0 20px;
  }

  .mobile-nav-hide {
    color: #ffffff;
    font-size: 32px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .about-img {
  min-height: 500px;
}

.about h3 {
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 30px;
  font-family: var(--font-secondary);
}

.about .call-us {
  left: 10%;
  right: 10%;
  bottom: 10%;
  background-color: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
  padding: 20px;
  text-align: center;
}

.about .call-us h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  font-family: var(--font-default);
}

.about .call-us p {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-primary);
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 0 0 8px 26px;
  position: relative;
}

.about .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: var(--color-primary);
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--color-primary) 50%, rgba(206, 18, 18, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(206, 18, 18, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.about .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Why Us Section
--------------------------------------------------------------*/
.why-us .why-box {
  padding: 30px;
  background: var(--color-primary);
  color: #fff;
}

.why-us h2{color: #101A24;text-align: center;font-family: Poppins;font-size: 48px;font-style: normal;font-weight: 600;line-height: 60px; /* 125% */letter-spacing: -0.96px;margin-bottom: 40px;margin-top: 60px;}

.why-us .why-box h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.why-us .why-box p {
  margin-bottom: 30px;
}

.why-us .why-box .more-btn {
  display: inline-block;
  background: rgba(255, 255, 255, 0.3);
  padding: 6px 30px 8px 30px;
  color: #fff;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

.why-us .why-box .more-btn i {
  font-size: 14px;
}

.why-us .why-box .more-btn:hover {
  color: var(--color-primary);
  background: #fff;
}

.why-us .icon-box {
  text-align: left;
  /* background: #fff; */
  padding: 30px;
  /* width: 100%; */
  height: 100%;
  /* border: 1px solid rgba(55, 55, 63, 0.1); */
  transition: 0.3s;
  border-radius: 16px;
  background: var(--Light-beige, #FFECDA);
}

.why-us img{
  /* margin-top:-180px; */
}


.why-us .icon-box h4 {
  color: #101A24;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.64px;
  text-align: left;
}

.why-us .icon-box p {
  color:#101A24;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: -0.32px;
  text-align: left;
}

@media (min-width: 1200px) {
  .why-us .icon-box:hover {
    transform: scale(1.1);
  }
}

/*--------------------------------------------------------------
# Stats Counter Section
--------------------------------------------------------------*/
.stats-counter {
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../public/img/stats-bg.jpg") center center; */
  background-size: cover;
  padding: 100px 0;
}

@media (min-width: 1365px) {
  .stats-counter {
    background-attachment: fixed;
  }
}

.stats-counter .stats-item {
  padding: 30px;
  width: 100%;
}

.stats-counter .stats-item span {
  font-size: 48px;
  display: block;
  color: #fff;
  font-weight: 700;
}

.stats-counter .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.6);
}

/*--------------------------------------------------------------
# Menu Section
--------------------------------------------------------------*/

.menu .section-header h2
{color: #101A24;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 60px; /* 125% */
letter-spacing: -0.96px;
}

.menu {
 /* margin-top: -15px; */
 padding-top: 35px;
}

.menu .section-header p
{
color: #CC3332;

font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 177.778% */
letter-spacing: 3.6px;
}


.menu .nav-tabs {
  border: 0;
}

.menu .nav-link {
  margin: 0 10px;
  padding: 10px 5px;
  transition: 0.3s;
  color: var(--color-secondary);
  border-radius: 0;
  cursor: pointer;
  height: 100%;
  border: 0;
  border-bottom: 4px solid #ffc794;
  text-align: center;
}

@media (max-width: 575px) {
  .menu .nav-link {
    margin: 0 10px;
    padding: 10px 0;
  }
}

.menu .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.menu .nav-link h4 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  font-family: var(--font-secondary);
  color: var(--Primary-black, #101A24);

/* H4 */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
  margin-top: 8px;
}

@media (max-width: 575px) {
  .menu .nav-link h4 {
    font-size: 16px;
  }
}

.menu .nav-link:hover {
  color: #c12f2e;
  background: transparent;
  border-color: #c12f2e;
}

.menu .nav-link.active {
  color: #CC3332;
  border-color: #CC3332;
  background: none;
}

.nested-tab .nav-link.active{
  border-color: #ffc794;
}

.nested-tab .nav-link:hover {
  color: #c12f2e;
  background: transparent;
  border-color: #ffc794 !important;
}

.menu .tab-content .tab-header {
  padding: 30px 0;
}

.menu .tab-content .tab-header p {
  font-size: 14px;
  text-transform: uppercase;
  color: #676775;
  margin-bottom: 0;
  text-align: left;
  color: var(--Primary-black, #101A24);

/* H4 */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
}

.menu .tab-content .tab-header h3 {
  font-size: 36px;
  font-weight: 600;
  color: var(--color-primary);
  color: var(--Primary-Red, #CC3332);

/* H4 */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
  text-align: left;
}

.menu .tab-content .menu-item {
 
  border-bottom: 2px solid #ffffff;
  min-height: 250px;
}

.menu-item .input-group{
width:20%;
}

.input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  background: #ffecda;
  padding: .2rem .45rem;
}

.menu .tab-content .menu-item .menu-img {
  padding: 0 60px;
  margin-bottom: 15px;
}

.menu .tab-content .menu-item h4 {
  font-size: 22px;
  font-weight: 500;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-weight: 30px;
  margin-bottom: 5px;
  color: var(--Primary-black, #101A24);

/* H4 */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
}

.menu .tab-content .menu-item .ingredients {
  font-family: var(--font-secondary);
  color: #8d8d9b;
  margin-bottom: 2px;
  color: #B37339;

/* Body */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.32px;
}

.menu .tab-content .menu-item .price {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-primary);
  color: var(--Primary-black, #101A24);

/* Body */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: -0.32px;
  min-height: 88px;
}

.price1{
  min-height: 53px;

}

.header-tab{
  border-radius: 16px;
  background:  #FFECDA;
  /* padding:16px 32px; */
  padding: 8px 32px 0px 32px;
  margin-bottom:50px;
  border-radius: 16px;
  border: 2px solid var(--Primary-beige, #FEDEC0);
  text-align: left;
}

.active1{
  border-radius: 16px;
  background:  #FFECDA;
  /* padding:16px 32px; */
  padding: 8px 32px 0px 32px;
  margin-bottom:50px;
  border-radius: 16px;
  border: 2px solid var(--Primary-beige, #FEDEC0);
  text-align: left;
}

.header-border {
  border-radius: 16px;
  background: #ffc794;
  padding: 8px 32px 0px 32px;
  margin-bottom: 50px;
  border: 2px solid  #FFECDA;
  text-align: left;
}

.header-border img{
  padding-right:16px;
  max-width: 100%;
  align-self: center
  
}


.header-tab img{
  padding-right:16px;
  max-width: 100%;
  align-self: center
  
}

.header-tab p, .header-border p{
  color: #101A24;

  /* H4 */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
}


/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item .testimonial-content {
  border-left: 3px solid var(--color-primary);
  padding-left: 30px;
}

.testimonials .testimonial-item .testimonial-img {
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: var(--color-default);
  font-family: var(--font-secondary);
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #6c757d;
  margin: 0 0 10px 0;
  font-family: var(--font-secondary);
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #f05656;
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
}

.testimonials .swiper-pagination {
  margin-top: 40px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #d1d1d7;
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

/*--------------------------------------------------------------
# Events Section
/* --------------------------------------------------------------*/
/*
.events{
margin-top:-60px;
}


.events .container-fluid {
  padding: 0 !important;
  padding-left:4% !important;
  
}

.events h2{
color:#101A24;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 60px; 
letter-spacing: -0.96px;
}

.events .event-item {
  background-size: cover;
  background-position: center;
  min-height: 740px;
  padding: 80px 80px;
  border-radius: 16px;
  background-image: url(./img/events-1.jpg);
}

@media (max-width: 575px) {
  .events .event-item {
    min-height: 500px;
  }
}



.events .event-item h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #fff;
  position: relative;
  color: #FFF;

  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.events .event-item .price {
  color: #fff;
  border-bottom: 2px solid var(--color-primary);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 15px;
  position: relative;
}

.events .event-item .description {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  color: #FFF;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; 
  letter-spacing: -0.32px;
}

@media (min-width: 1200px) {
  .events .swiper-slide-active+.swiper-slide {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 1;
  }
}

.events .swiper-pagination {
  margin-top: -30px;
  position: relative;
}

.events .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #FFC794;
  opacity: 1;
}

.events .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.slides-3 {
  overflow: hidden;
} 
*/

/*--------------------------------------------------------------
# Chefs Section
--------------------------------------------------------------*/
.chefs .chef-member {
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0 30px rgba(55, 55, 63, 0.08);
  transition: 0.3s;
}

.chefs .chef-member .member-img {
  position: relative;
  overflow: hidden;
}

.chefs .chef-member .member-img:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  /* background: url(../public/img/team-shape.svg) no-repeat center bottom; */
  background-size: contain;
  z-index: 1;
}

.chefs .chef-member .social {
  position: absolute;
  right: -100%;
  top: 30px;
  opacity: 0;
  border-radius: 4px;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2;
}

.chefs .chef-member .social a {
  transition: color 0.3s;
  color: rgba(55, 55, 63, 0.4);
  margin: 15px 12px;
  display: block;
  line-height: 0;
  text-align: center;
}

.chefs .chef-member .social a:hover {
  color: rgba(55, 55, 63, 0.9);
}

.chefs .chef-member .social i {
  font-size: 18px;
}

.chefs .chef-member .member-info {
  padding: 10px 15px 20px 15px;
}

.chefs .chef-member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: var(--color-secondary);
}

.chefs .chef-member .member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 37, 41, 0.4);
}

.chefs .chef-member .member-info p {
  font-style: italic;
  font-size: 14px;
  padding-top: 15px;
  line-height: 26px;
  color: rgba(33, 37, 41, 0.7);
}

.chefs .chef-member:hover {
  transform: scale(1.08);
  box-shadow: 0px 0 30px rgba(55, 55, 63, 0.15);
}

.chefs .chef-member:hover .social {
  right: 8px;
  opacity: 1;
}

/*--------------------------------------------------------------
# Book A Table Section
--------------------------------------------------------------*/
.book-a-table .reservation-img {
 background-color: #FFDEBF;
 padding: 80px;
}


.book-a-table .reservation-form-bg {
  
  padding-top:100px;
  background-color:#CC3332;
  padding:80px;
}



.book-a-table .reservation-form-bg p{
color: var(--Primary-beige, #FEDEC0);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
letter-spacing: -0.32px;
}

.reservation-img h3{
  color:  #101A24;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
  letter-spacing: -0.64px;
}

.reservation-img h2{
  color: #CC3332;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.96px;
  margin-bottom: 16px;
}

.reservation-img .info-item img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  line-height: 0;
  margin-right: 15px;

}

.reservation-img h4{
  color:  #101A24;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
}

.reservation-img p{
  color: var(--Primary-black, #101A24);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: -0.32px;
}


.reservation-img h4 span{
  color: #cc3332;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Gallery Section
--------------------------------------------------------------*/
.gallery {
  overflow: hidden;
}

.gallery .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.gallery .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #d1d1d7;
  opacity: 1;
}

.gallery .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.gallery .swiper-slide-active {
  text-align: center;
}

@media (min-width: 992px) {
  .gallery .swiper-wrapper {
    padding: 40px 0;
  }

  .gallery .swiper-slide-active {
    border: 6px solid var(--color-primary);
    padding: 4px;
    background: #fff;
    z-index: 1;
    transform: scale(1.2);
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
  background: #f4f4f4;
  padding: 30px;
  height: 100%;
}

.contact .info-item .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  line-height: 0;
  color: #fff;
  background: var(--color-primary);
  border-radius: 50%;
  margin-right: 15px;
}

.contact .info-item h3 {
  font-size: 20px;
  color: #6c757d;
  font-weight: 700;
  margin: 0 0 5px 0;
}

.contact .info-item p {
  padding: 0;
  margin: 0;
  line-height: 24px;
  font-size: 14px;
}

.contact .info-item .social-links a {
  font-size: 24px;
  display: inline-block;
  color: rgba(55, 55, 63, 0.7);
  line-height: 1;
  margin: 4px 6px 0 0;
  transition: 0.3s;
}

.contact .info-item .social-links a:hover {
  color: var(--color-primary);
}

.contact .php-email-form {
  width: 100%;
  margin-top: 30px;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}

.contact .php-email-form .form-group {
  padding-bottom: 20px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.contact .php-email-form input {
  height: 48px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 12px 40px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #ec2727;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  background-size: cover;
  position: relative;
  min-height: 60vh;
  padding: 160px 0 160px 0;
  background: url("./img/hero.png") no-repeat;
  background-position: center;
  
}

.hero h3{
  color:#FEDEC0;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 130%; 
}

.hero h2 {color:#FFECDA;font-size: 72px;font-style: normal;font-weight: 700;line-height: 130%; /* 72.8px */letter-spacing: -1.12px;}

.hero h2 span {
   color: #FFC794;
}

.hero p {
  color:  #FEDEC0;
  
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; 
  letter-spacing: -0.32px;
}

.hero .btn-book-a-table {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 36px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: var(--color-primary);
  box-shadow: 0 8px 28px rgba(206, 18, 18, 0.2);
}

.hero .btn-book-a-table:hover {
  background: rgba(206, 18, 18, 0.8);
  box-shadow: 0 8px 28px rgba(206, 18, 18, 0.45);
}

.hero-img{
  position: absolute;
  top: 16%;
  right:-5%;
  content:"";
  
}

@media (max-width: 640px) {
  .hero h2 {
    font-size: 36px;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 14px;
  background-color: #101A24;
  padding: 50px 0;
  color: #FEDEC0;
}

.footer .icon {
  margin-right: 15px;
  font-size: 24px;
  line-height: 0;
  color: #c12f2e;
}

.footer h4 {
  
  position: relative;
  padding-bottom: 16px;
  color: #c12f2e;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-right: 20px;
}

.footer .footer-links ul li{
 /* margin-bottom: 24px; */
 margin-right: 20px;
}


.footer .footer-links ul a {
  color: #FEDEC0;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-align: left;
}

.footer .footer-links ul a:hover {
  color: #FEDEC0;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  font-size: 24px;
  color: #CC3332;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #CC3332;
}



.cta{
  background: #FEDEC0;
  padding-top:80px;
  padding-bottom:0;
}


.cta h2{
color:  #101A24;

font-size: 56px;
font-style: normal;
font-weight: 700;
line-height: 130%; /* 72.8px */
letter-spacing: -1.12px;
}

.cta h4{
color:  #CC3332;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 44.8px */
letter-spacing: -0.64px;
}

.cta-two{
position: relative;
padding-top:160px;
padding-bottom:0;
}

.cta-box{
  background-color: #101A24;
  padding:100px 100px;
  border-radius:16px;
}

.cta-box-img {
  position: absolute;
  bottom: 0%;
  right: 20%;
  content: "";
}

.cta-box h2{
  color: #CC3332;
font-family: Poppins;
font-size: 60px;
font-style: normal;
font-weight: 500;
line-height: 80%; /* 96px */
letter-spacing: -1.2px;

}

.cta-box h4{
  color: #FEDEC0;
font-family: Poppins;
font-size: 50px;
font-style: normal;
font-weight: 600;
line-height: 160%; /* 80px */
letter-spacing: -1px;
}


.why-us{
  background: url("./img/background-light.png") no-repeat center;
  padding: 20px 0px;
}

.flavors{border-radius: 2px;/* background: #FEDEC0; *//* padding:120px 0; */}

.flavors h2{color:  #101A24;font-size: 48px;font-style: normal;font-weight: 600;line-height: 60px; /* 125% */letter-spacing: -0.96px;text-align: left;}

.flavors p{
color:  #101A24;

/* Body */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 187.5% */
letter-spacing: -0.32px;
}

.flavors .info-item {
  /* background: #f4f4f4; */
  padding: 20px 0;
  /* height: 100%; */
}

.flavors .info-item .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  line-height: 0;
  color: #fff;
  background: var(--color-primary);
  border-radius: 50%;
  margin-right: 15px;
}

.flavors .info-item h3 {
  color:  #101A24;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
}


.form-btn{
border-radius: 14px !important;
background:  #CC3332 !important;
  color:  #FEDEC0 !important;
font-family: Poppins !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 30px !important;
padding: 12px 32px;
}

.form-btn:hover{
  border-radius: 8px;
  background:  #CC3332;
    color:  #FEDEC0;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: -0.32px;
  }


.place-order{
  background: var(--Primary-Red, #CC3332);
  padding:20px 0px 10px 0px;
}

.place-order h4{
color: var(--Primary-beige, #FEDEC0);
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 140%;
letter-spacing: -0.4px;
}

.place-order h5{
  color: var(--Primary-beige, #FEDEC0);
  }


.place-order-btn{
    border-radius: 8px;
background: var(--Primary-beige, #FEDEC0);
color: var(--Primary-Red, #CC3332);
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 21px; /* 116.667% */
text-align: center;
padding:12px 32px;
  }

  .place-order-btn:hover{
  
background: var(--Primary-beige, #FEDEC0);
color: var(--Primary-Red, #CC3332);

  }

  .place-order-btn:first-child:active{
    background: var(--Primary-beige, #FEDEC0);
color: var(--Primary-Red, #CC3332);
  }

  .modal-body {
  
    padding-bottom: 0;
}

.modal-footer {
 
  padding: 10px;
  
  border-top: none;

}

  .modal-body h4{
color: var(--Primary-black, #101A24);
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 33px; /* 150% */
  }

  .modal-body .form-floating>.form-control, .modal-body .form-floating>.form-control-plaintext, .modal-body .form-floating>.form-select {
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #E8E8E8;
    padding: 0 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #a3a3a3;
}

.modal-body .form-floating>label {
 
  background: #ffffff;
}


.modal-body .form-floating>.form-control-plaintext~label::after, 
.modal-body .form-floating>.form-control:focus~label::after, 
.modal-body .form-floating>.form-control:not(:placeholder-shown)~label::after, 
.modal-body .form-floating>.form-select~label::after {

  background-color: #ffffff;
 
}



.scrollable-section {
  max-height: 300px; /* Set the maximum height for the scrollable section */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 8px 8px 0 0;
  border-top: 1px solid var(--Primary-Red, #CC3332);
  border-left: 1px solid var(--Primary-Red, #CC3332);
  border-bottom: 1px solid var(--Primary-Red, #CC3332);
  border-bottom:none;
  padding: 10px; /* Optional padding */
}

 /* Custom styles for the scrollbar in WebKit browsers */
 .scrollable-section::-webkit-scrollbar {
  width: 1px; /* Set the width of the scrollbar */
}

.scrollable-section::-webkit-scrollbar-thumb {
  background-color: #CC3332; /* Set the color of the scrollbar thumb (drag handle) */
}

.scrollable-section::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the scrollbar track (background) */
}

.order-total{
  background-color: #FEDEC0;
  padding: 20px 20px 0 20px;
}


.order-total h4{
  color: var(--Primary-black, #101A24);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: -0.32px;
}

.order-total hr {
  color: #EBA531;
  height: 4px;
  opacity: 1;
}

.order-total h5{
color: var(--Primary-Red, #CC3332);
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
letter-spacing: -0.4px;
}

.item-list{
  border-bottom: 1px solid var(--Primary-dark-beige, #FFC794);

}
.item-list h4{
  color: var(--Primary-black, #101A24);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
}

.item-list p{
  color: var(--Primary-black, #101A24);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
margin-bottom:5px;
letter-spacing: -0.32px;
}

.item-list .form-control {
  border:none;
  padding-right:20px;
  padding-top:0;
  padding-bottom: 0;
  color: var(--Primary-black, #101A24);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
letter-spacing: -0.32px;
}
.form1 {
  border:none;
  padding-right:20px;
  padding-top:0;
  padding-bottom: 0;
  color: var(--Primary-black, #101A24);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
letter-spacing: -0.32px;
position: relative;
flex: 1 1 auto;
min-width: 0;
background: #ffecda;
padding: .2rem .45rem;
}

.custom-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("./img/arrow.svg"); /* Replace with the path to your custom arrow icon */
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 30px; /* Adjust the padding to leave space for the custom arrow */
  padding-left:0;
}

.item-list p.small{
color: rgba(16, 26, 36, 0.40);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
letter-spacing: -0.28px;
}



.placeorder-background{
  background-color: #CC3332;
  padding:0;
}
.pointer {
  cursor: pointer;
}

input[type=number] {
  /*for absolutely positioning spinners*/
  position: relative; 
  padding: 5px;
  padding-right: 25px;
  
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
  background-color: red !important;
}

input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: red !important;
 
}

.custom-number-input {
  display: flex;
  align-items: center;
}

.custom-input {
  width: 50px; /* Adjust width as needed */
  text-align: center;
}

.custom-icon {
  width: 25px;
  height: 25px;
  background-color: red; /* Set the color of the custom icons */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}

.custom-icon:hover {
  background-color: darkred; /* Set the color when hovered */
}

/*--------------------------------------------------------------
# Events Section
--------------------------------------------------------------*/
.events{
  margin-top:-60px;
  }
  
  .events .container-fluid {
    padding: 0 !important;
    padding-left:4% !important;
    
  }
  
  .events h2{
color: var(--Primary-black, #101A24);
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 60px; /* 125% */
letter-spacing: -0.96px;
text-align: left;
  }
  
  .events .event-item {
    background-image: url(./img/events-1.jpg);
    background-position: 50%;
    background-size: cover;
    border-radius: 16px;
    min-height: 894px;
    padding: 80px;
}
  
  .events .event-item h3 {
    color: var(--Primary-black, #101A24);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    letter-spacing: -0.4px;
  }
  
  .events .event-item .price {
    color: #fff;
    border-bottom: 2px solid var(--color-primary);
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 15px;
    position: relative;
  }
  
  .events .event-item .description {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
    letter-spacing: -0.32px;
  }
  
  @media (min-width: 1200px) {
    .events .swiper-slide-active+.swiper-slide {
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      z-index: 1;
    }
  }
  
  .events .swiper-pagination {
    margin-top: -30px;
    position: relative;
  }
  
  .events .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #FFC794;
    opacity: 1;
  }
  
  .events .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--color-primary);
  }
  
  .slides-3 {
    overflow: hidden;
  }



 

  .footer .footer-links {
    margin-bottom: 0px;
}

  .footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0 !important;
display: block;
    margin-right: 20px;
}

  .footer .footer-links ul li {
    margin-bottom: 24px;
    /* margin-right: 20px; */
}

.book-a-table .reservation-img {
  padding: 100px;
}

.events .swiper-pagination {
  margin-top: -30px;
  position: relative;
  display: none;
}


  @media screen and (max-width: 991px) {
    .book-a-table .reservation-img {
      padding: 40px;
    }

    .menu .tab-content .menu-item {
        border-bottom: 2px solid #fff;
        min-height: unset;
    }

    .place-order h4, .place-order h5 {
      color: #FEDEC0;
      color: var(--Primary-beige,#FEDEC0);
      text-align: center;
  }
  
  .menu-item .input-group {
    width: 40%;
}
    }


    @media only screen and (min-width: 991px) and (max-width: 1085px) {
      .events .event-item {
        
        min-height:1200px;
        
    }
    }


    @media only screen and (min-width:576px) and (max-width: 990px) {
      .events .event-item {
        
        min-height:1450px;
        
    }
    }
  


    @media (max-width: 575px) {
      .events .event-item {
        min-height:auto;
      }
    
      .events .event-item {
       
        padding:32px;
        background-color: #ffecda;
        background-image: none;
       
      }

      .events h2 {
        font-size: 24px;
        line-height: 28px;
      }
      
      .flavors .info-item {
        /* background: #f4f4f4; */
        padding: 4px 0;
        /* height: 100%; */
      }
      
      .flavors p {
      
        font-size: 14px;
        
        line-height: 20px;
      
      }
  
      .hero-img {
        content: "";
        position: absolute;
        right: -8%;
        top: 75%;
        display: none;
    }
  }

  